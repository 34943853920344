import styles from './Home.module.css';
import {FadeInText, FadeInSubText, FadeInPara, FadeInImg} from './FadeInText';
import cloud from '../images/cloud.jpg';


const Home =() =>
{
    return(<>
    <div className={styles.homecontent}>
        <FadeInText text ="Welcome to RSD IT Services"/>
        <FadeInSubText text ="Empowering Your Business in the Digital World"/>
        <div className={styles.homecard}>
          <FadeInImg  className={styles.imgStyle} src ={cloud} ></FadeInImg>
          <div className={styles.homecontentcard}>
            <FadeInPara text="At RSD IT Services, we are dedicated to helping businesses transition seamlessly into the digital age.
            By reducing manual work and implementing automation, we streamline your operations, saving you time and resources while driving efficiency.">
          </FadeInPara>
          <FadeInPara text="We believe that every business is unique, and we take pride in building solutions from the ground up to match your specific needs. Our team works with precision, focusing on every detail to deliver exceptional results.
           Whether you’re starting from scratch or improving existing systems, we guide you toward success with innovative digital solutions tailored for your business.">
          </FadeInPara>
          <FadeInPara text="Let us take care of your technology, so you can focus on what matters most—growing your business.">
          </FadeInPara>
          </div>
        </div>
    </div>  
    </>)
}

export default Home;