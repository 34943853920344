import {delay, motion} from "framer-motion";
import styles from './Home.module.css';
const visible = { opacity: 1, y: 0, transition: { duration: 0.5 } };
const subTextVisible = { opacity: 1, y: 0, transition: { duration: 0.8 } };
const contentVisible = { opacity: 1, y: 0, transition: { duration: 1.0 } };
const hidden =  { opacity: 0, y: -50 };

const FadeInText = (props) =>
{
    return(<>
    <motion.article>
      <motion.h1 initial="hidden" animate="visible"   variants={{hidden,visible}} style={{margin:0}}>
        {props.text}
      </motion.h1>
      </motion.article>
    </>)
}

const FadeInSubText = (props) =>
  {
      return(<>
      <motion.article>
        <motion.div initial="hidden" animate="subTextVisible"  variants={{hidden,subTextVisible}}>
          {props.text}
        </motion.div>
        </motion.article>
      </>)
  }

  const FadeInPara = (props) =>
    {
        return(<>
        <motion.article>
          <motion.p initial="hidden" animate="contentVisible"  variants={{hidden,contentVisible}}>
            {props.text}
          </motion.p>
          </motion.article>
        </>)
    }

    const FadeInImg = (props) =>
      {
          return(<>
         
            <motion.img initial="hidden" className={styles.imgStyle} src={props.src} animate="contentVisible"  variants={{hidden,contentVisible}}>
            </motion.img>
          </>)
      }
  

export {FadeInText, FadeInSubText, FadeInPara, FadeInImg };