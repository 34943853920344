import styles from './Footer.module.css';
import logo from '../images/Logo.png';

const Footer = () =>
{
    return(
        <>
    <footer>
        <div className={styles.navbar}>
        <div>
           <h4>Contact Us</h4>
           <ul>
           <ul>RSD IT Services</ul>
           <ul>xxx, xxx Cherryhill Place</ul>
            <ul>xxxxxx, Ontario, xxx xxx</ul>
            <ul>Phone: (xxx) xxx-xxxx</ul>
            <ul>Email: <a href="mailto:xxxxxxxxx@gmail.com">xxxxxx@rsditservices.com</a></ul>
            <ul>Website: <a href="https://rsditservices.com">https://rsditservices.com</a></ul>
           </ul>
           </div>
        <div>
        <h4>Our Services</h4>
        <ul>
          <ul>Brochure Website Development</ul>
          <ul>Business Web Application Development</ul>
          <ul>Cloud Services Management</ul>
          <ul>Hosting Solutions</ul>
          <ul>Business Digital Transformation</ul>
          <ul>Process Automation and Digitization</ul>
        </ul>
        </div>
        <div>
            <h4>Useful links</h4>
            <ul>
            <ul><a href="/services">Services</a></ul>
            <ul><a href="/producrs">Products</a></ul>
            <ul><a href="/contact">Contact</a></ul>
            </ul>
        </div>
        <div>
        <img src={logo} className={styles.applogo} alt="Logo"></img>  
        </div>
        </div>
    </footer>
    </>
)
}

export default Footer